import {Component, ElementRef, Inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NomChartComponent} from 'frontend/src/shared/components/nom-chart/nom-chart.component';
import {DOCUMENT} from '@angular/common';
import {GenOutageAggregationLevel} from 'frontend/generated/serverModels/GenOutageAggregationLevel';
import moment from 'moment';
import {State} from '../../modules/outage/classes/state';
import {DataService} from '../../services/data.service';
import {SitRepMapComponent} from '../../modules/report/components/sit-rep-map/sit-rep-map.component';
import {element} from 'protractor';

@Component({
    selector: 'eaglei-export-component',
    templateUrl: './export-component.component.html',
    styleUrls: ['./export-component.component.scss'],
})
export class ExportComponentComponent {
    protected exporting: any = {};

    constructor(private route: ActivatedRoute, @Inject(DOCUMENT) document: Document, private elRef: ElementRef) {
        // disable some default app styling
        const banner = document.getElementById('bannerText');
        if (banner) {
            banner.style.display = 'none';
            document.getElementById('eaglei-browser-loading-mask').classList.remove('below-banner-text');
            document.getElementById('eaglei-browser-loading-mask').classList.remove('second-training-banner');
            document.getElementById('eaglei-browser-loading-mask').classList.add('no-banner-text');
        }
        const basePage = document.getElementsByTagName('eaglei-base-page')[0] as HTMLElement;
        basePage.style.bottom = 'unset';

        // Set component
        this.exporting.component = SitRepMapComponent;
        switch (this.route.snapshot.queryParamMap.get('component')) {
            case 'eaglei-nom-chart':
                this.exporting.component = NomChartComponent;
                break;
        }

        // set height and width if passed in
        if (this.route.snapshot.queryParamMap.get('height') !== null) {
            elRef.nativeElement.style.height = this.route.snapshot.queryParamMap.get('height') + 'px';
        }
        if (this.route.snapshot.queryParamMap.get('width') !== null) {
            elRef.nativeElement.style.width = this.route.snapshot.queryParamMap.get('width') + 'px';
        }

        // Set inputs and fix data types
        const tmpInputs = JSON.parse(this.route.snapshot.queryParamMap.get('inputs') || '{}');
        this.exporting.inputs = {};
        const autoChangeTypes = ['aggregationLevel', 'startDate', 'endDate', 'timestamp', 'states'];
        for (const key of Object.keys(tmpInputs).filter((k) => k.charAt(0) !== '_')) {
            if (!(`_${key}_type` in tmpInputs) && autoChangeTypes.includes(key)) {
                tmpInputs[`_${key}_type`] = key;
            }
            switch (tmpInputs[`_${key}_type`]) {
                case 'aggregationLevel':
                    this.exporting.inputs[key] = GenOutageAggregationLevel.forName(tmpInputs[key]);
                    break;
                case 'startDate':
                case 'endDate':
                case 'timestamp':
                    this.exporting.inputs[key] = moment(tmpInputs[key]);
                    break;
                case 'states':
                    const stateMap = new Map<string, State>();
                    DataService.states
                        .getValue()
                        .filter((state) => tmpInputs[key].includes(state.abbreviation))
                        .forEach((state) => stateMap.set(state.abbreviation, state));
                    this.exporting.inputs[key] = Array.from(stateMap.values());
                    break;
                default:
                    this.exporting.inputs[key] = tmpInputs[key];
            }
        }

        // hide any elements that don't export
        setTimeout(() => {
            document.querySelectorAll('.hide-on-export').forEach((elem) => ((elem as HTMLElement).style.display = 'none'));
        }, 100);
    }
}
